// stickers
export const FREE_TIMEOUT = 200;
export const STICKER_DURATION = 800;

// questions
export const OPTION_DURATION = 1000;
export const OPTION_DELAY = 200;
export const OPTION_TOTAL = OPTION_DURATION + OPTION_DELAY;

export const CORRECT_ANS_ANIM_DURATION = 3500;
export const INCORRECT_ANS_ANIM_DURATION = 4000;

export const STICKER_TIMEOUT = STICKER_DURATION + FREE_TIMEOUT;
export const CONFIRM_TIMEOUT = STICKER_TIMEOUT + FREE_TIMEOUT;
export const QUESTION_CORRECT_TIMEOUT = CORRECT_ANS_ANIM_DURATION + OPTION_TOTAL + FREE_TIMEOUT;
export const QUESTION_INCORRECT_TIMEOUT = INCORRECT_ANS_ANIM_DURATION + OPTION_TOTAL + FREE_TIMEOUT;

import bearSmile from 'assets/img/stickers/bearSmile.webp';
import bearThink from 'assets/img/stickers/bearThink.webp';
import bed from 'assets/img/stickers/bed.webp';
import bye from 'assets/img/stickers/bye.webp';
import cocky from 'assets/img/stickers/cocky.webp';
import dream from 'assets/img/stickers/dream.webp';
import glad from 'assets/img/stickers/glad.webp';
import hi from 'assets/img/stickers/hi.webp';
import idea from 'assets/img/stickers/idea.webp';
import idle from 'assets/img/stickers/idle.webp';
import idleSmile from 'assets/img/stickers/idleSmile.webp';
import idleStand from 'assets/img/stickers/idleStand.webp';
import nightCap from 'assets/img/stickers/nightCap.webp';
import smile from 'assets/img/stickers/smile.webp';
import sorry from 'assets/img/stickers/sorry.webp';
import think from 'assets/img/stickers/think.webp';
import what from 'assets/img/stickers/what.webp';
import whatReverse from 'assets/img/stickers/whatReverse.webp';
import payment from 'assets/img/stickers/payment.webp';
import textBubble from 'assets/img/stickers/textBubble.webp';

export type Stickers = { [key: string]: string };

const stickers: Stickers = {
  bearSmile,
  bearThink,
  bed,
  bye,
  cocky,
  dream,
  glad,
  hi,
  idea,
  idle,
  idleSmile,
  idleStand,
  nightCap,
  smile,
  sorry,
  think,
  what,
  whatReverse,
  payment,
  textBubble,
};

export const getStickerById = (imageId: keyof Stickers) => {
  return stickers[imageId];
};

import React from 'react';
import styled, { css } from 'styled-components';

export const Button = styled.button<{ focused?: boolean }>`
  height: 11.11vh;
  width: 11.11vh;
  border-radius: 50%;
  backdrop-filter: blur(5.034vh);
  opacity: 1;

  background-color: rgba(0, 0, 0, 0.16);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 26.666%;
  border: 0.37vh solid transparent;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: border 0.2s ease, opacity 0.2s ease-in;

  ${({ focused }) =>
    focused &&
    css`
      border: 0.37vh solid ${({ theme }) => theme.colors.sberGreen};
    `}
`;

export const Icon = styled.img`
  height: 28.666%;
`;

export type Props = {
  icon?: string;
  focused?: boolean;
  className?: string;
  children?: JSX.Element;
  onClick?: () => void;
};

const ControlButton = ({ icon, focused, className, children, onClick }: Props): JSX.Element => (
  <Button
    focused={focused}
    className={className}
    onClick={(e) => {
      e.stopPropagation();
      if (onClick) onClick();
    }}
  >
    {children !== undefined ? children : <Icon src={icon} alt="Иконка кнопки управления плеером" />}
  </Button>
);

export default React.memo(ControlButton);

import React from 'react';
import styled, { css } from 'styled-components';

import { getTime } from 'helpers/utils';
import { useDevice } from 'helpers/hooks';

import homeIcon from 'assets/img/icons/home-icon.svg';
import prevIcon from 'assets/img/icons/prev-icon.svg';
import nextIcon from 'assets/img/icons/next-icon.svg';

import ControlButton, { Button, Icon } from './ControlButton';
import PauseResumeButton from './PauseResumeButton';

const Wrapper = styled.main`
  position: absolute;
  top: 53%;
  width: 87.08vw;

  transition: visibility 0.2s ease-in 0.1s, top 0.2s ease-in 0.1s;
`;

const ControlButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 6.666vh;
`;

const InvisibleCell = styled.div`
  height: 11.11vh;
  width: 11.11vh;
`;

const ProgressWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  opacity: 1;
  transition: opacity 0.2s ease-in 0.1s;
`;

const Progress = styled.progress`
  height: auto;
  width: 100%;
  margin: 0 3.542vw;

  ::-webkit-progress-bar {
    height: 0.185vh;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 0.0925vh;
    backdrop-filter: blur(6.851vh);

    display: flex;
    align-items: center;
  }

  ::-webkit-progress-value {
    background: linear-gradient(0deg, #ffffff, #ffffff);
    border-radius: 0.185vh;
    height: 0.37vh;

    transition: width 0.2s ease-in;
  }
`;

const Time = styled.span`
  font-family: SB Sans Display;
  font-size: 2.592vh;
  line-height: 3.703vh;
  letter-spacing: 0.015em;
`;

const Mask = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;

  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.68) 100%);
  background-repeat: no-repeat;
  background-position-y: 0;

  transition: background-position-y 0.15s ease-in;

  ${Button} {
    transition: border 0.2s ease, opacity 0.2s ease-in 0.1s;
  }

  ${({ visible }) =>
    !visible &&
    css`
      background-position-y: 100vh;

      ${Wrapper} {
        visibility: hidden;
        top: 58%;
      }

      ${Button},
      ${ProgressWrapper} {
        opacity: 0;
      }
    `}
`;

const HomeButton = styled(ControlButton)`
  ${Icon} {
    height: 41%;
  }
`;

type Props = {
  focusedControl: string | null;
  playing: boolean;
  played: number;
  duration: number;
  onTogglePlaying: () => void;
  onGoPrevVideo: () => void;
  onGoNextVideo: () => void;
  onGoHome: () => void;
};

const Controls = ({
  focusedControl,
  playing,
  played,
  duration,
  onTogglePlaying,
  onGoPrevVideo,
  onGoNextVideo,
  onGoHome,
}: Props) => {
  const isSberPortal = useDevice();
  return (
    <Mask visible={!playing} onClick={onTogglePlaying}>
      <Wrapper>
        <ControlButtonsWrapper>
          <HomeButton
            icon={homeIcon}
            onClick={isSberPortal ? onGoHome : undefined}
            focused={focusedControl === 'home'}
          />
          <ControlButton
            icon={prevIcon}
            onClick={isSberPortal ? onGoPrevVideo : undefined}
            focused={focusedControl === 'goPrev'}
          />
          <PauseResumeButton
            playing={playing}
            onClick={isSberPortal ? onTogglePlaying : undefined}
            focused={focusedControl === 'pauseResume'}
          />
          <ControlButton
            icon={nextIcon}
            onClick={isSberPortal ? onGoNextVideo : undefined}
            focused={focusedControl === 'goNext'}
          />
          <InvisibleCell />
        </ControlButtonsWrapper>

        <ProgressWrapper>
          <Time>{getTime(played * duration)}</Time>

          <Progress max={1} value={played} />

          <Time>{getTime(duration)}</Time>
        </ProgressWrapper>
      </Wrapper>
    </Mask>
  );
};

export default React.memo(Controls);

import React, { useEffect, useState } from 'react';
import ProgressiveImage from 'react-progressive-image';
import styled, { keyframes } from 'styled-components';

import { ScreenWrapper } from 'components/ui';
import homeBg from 'assets/img/backgrounds/home-bg.webp';
import homeBgPrev from 'assets/img/backgrounds/home-bg-preview.webp';

const fade = keyframes`
        0% {
          opacity:1;
        }
        100% {
          opacity:0;
        }
      `;

const BlackFadeOut = styled.div`
  background-color: #000;
  height: 100vh;
  position: absolute;
  width: 100vw;
  z-index: 99999;
  animation: ${fade} 1000ms ease-in-out 1;
`;

interface Props {
  startFromFade?: boolean;
}

const Stars = ({ startFromFade = false }: Props) => {
  const [isBlack, setIsBlack] = useState(startFromFade);

  useEffect(() => {
    if (isBlack)
      setTimeout(() => {
        setIsBlack(false);
      }, 1000);
  }, [isBlack]);

  return (
    <>
      {isBlack && <BlackFadeOut />}
      <ProgressiveImage src={homeBg} placeholder={homeBgPrev}>
        {(src: string) => <ScreenWrapper bg={`url(${src}) #1e278d`} />}
      </ProgressiveImage>
    </>
  );
};

export default React.memo(Stars);

import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player/lazy';
import styled, { keyframes } from 'styled-components';
import useSound from 'use-sound';

import { useAssistant, useDevice, useVideoControls } from 'helpers/hooks';
import { ScreenWrapper } from 'components/ui';
import { CardItem } from 'components/Home';
import clickSound from 'assets/sounds/btn-click.wav';

import Controls from './Controls';

const Player = styled(ReactPlayer)`
  width: 100%;
  height: 100%;
  overflow: hidden;

  video {
    object-fit: cover;
  }
`;

const fade = keyframes`
        0% {
          opacity:1;
        }
        80% {
          opacity:1;
        }
        100% {
          opacity:0;
        }
      `;

const BlackFadeOut = styled.div`
  background-color: #000;
  height: 100vh;
  position: absolute;
  width: 100vw;
  z-index: 99999;
  animation: ${fade} 2000ms ease-in-out 1;
`;

type OnProgress = ReactPlayerProps['onProgress'];

type Props = {
  onGoHome: () => void;
  onGoNext?: () => void;
  onGoPrev?: () => void;
  onVideoEnded?: () => void;
  paused?: boolean;
  currentVideoInfo: {
    src: string;
    videoId: number | undefined;
  };
  setCurrentVideoInfo: React.Dispatch<
    React.SetStateAction<{
      src: string;
      videoId: number | undefined;
    }>
  >;
  cardsList: CardItem[];
  inputOnTimeoutStart: Function;
};

const Video = ({
  onGoHome,
  onVideoEnded,
  paused,
  currentVideoInfo,
  setCurrentVideoInfo,
  cardsList,
  inputOnTimeoutStart,
}: Props) => {
  const isSberPortal = useDevice();
  const [playing, setPlaying] = useState(true);
  const [currentUrlIdx, setCurrentUrlIdx] = useState(
    cardsList.indexOf(cardsList.find((card) => card?.videoId === currentVideoInfo?.videoId) as CardItem),
  );
  const [played, setPlayed] = useState<number>(0);
  const [duration, handleDuration] = useState<number>(-1);
  const [isBlack, setIsBlack] = useState<boolean>(true);
  const sendInfoReadyHome = useRef<boolean>(false);
  const taleEndReady = useRef<boolean>(false);
  const assistant = useAssistant();
  const [playClick] = useSound(clickSound, {
    interrupt: true,
  });

  const NUM_VIDEOS = useMemo(() => cardsList.length, [cardsList.length]);
  const playingActually = useMemo(() => (paused === undefined ? playing : !paused && playing), [paused, playing]);

  const handleTogglePlaying = useCallback(() => {
    playClick();
    setPlaying((prevPlaying) => !prevPlaying);
  }, [playClick]);

  useEffect(() => {
    if (isBlack)
      setTimeout(() => {
        setIsBlack(false);
      }, 2000);
  }, [isBlack]);

  const sendInputOn = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    assistant?.sendData({
      action: {
        action_id: 'inputOn',
        parameters: {
          auto_listening: false,
        },
      },
      name: 'SERVER_ACTION',
    });
    inputOnTimeoutStart(assistant);
  }, [assistant, inputOnTimeoutStart]);

  const handleGoPrevVideo = useCallback(() => {
    playClick();
    setPlaying(false);
    setCurrentUrlIdx((prevIdx) => {
      const calcIndex = (prevIdx + NUM_VIDEOS - 1) % NUM_VIDEOS;
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      assistant?.sendData({
        action: {
          action_id: cardsList[calcIndex]?.event,
          parameters: { video_id: cardsList[calcIndex]?.videoId },
        },
        name: 'SERVER_ACTION',
      });
      return calcIndex;
    });
  }, [NUM_VIDEOS, assistant, cardsList, playClick]);

  const handleGoNextVideo = useCallback(() => {
    playClick();
    setPlaying(false);
    setCurrentUrlIdx((prevIdx) => {
      const calcIndex = (prevIdx + 1) % NUM_VIDEOS;
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      assistant?.sendData({
        action: {
          action_id: cardsList[calcIndex]?.event,
          parameters: { video_id: cardsList[calcIndex]?.videoId },
        },
        name: 'SERVER_ACTION',
      });
      return calcIndex;
    });
  }, [NUM_VIDEOS, assistant, cardsList, playClick]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    assistant?.on('data', (data: any) => {
      if ('actions' in data) {
        if (data?.actions[0]?.type === 'PLAY') {
          playClick();
          setPlaying(true);
        }
        if (data?.actions[0]?.type === 'STOP') {
          playClick();
          setPlaying(false);
        }
        if (data?.actions[0]?.type === 'NEXT') {
          handleGoNextVideo();
        }
        if (data?.actions[0]?.type === 'PREV') {
          handleGoPrevVideo();
        }
        if (data?.actions[0]?.type === 'INPUT_ON' && sendInfoReadyHome.current) {
          sendInfoReadyHome.current = false;
          assistant.sendData({
            action: { action_id: 'homeButton' },
            name: 'SERVER_ACTION',
          });
          onGoHome();
        }
        if (data?.actions[0]?.type === 'INPUT_ON' && taleEndReady.current) {
          taleEndReady.current = false;
          assistant.sendData({
            action: { action_id: 'taleEnds' },
            name: 'SERVER_ACTION',
          });
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assistant]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    assistant?.on('data', (data: any) => {
      if ('actions' in data) {
        if (data?.actions[0]?.type === 'PLAY_VIDEO') {
          setIsBlack(true);
          setCurrentVideoInfo({
            src: isSberPortal ? data?.actions[0]?.payload.url_hd : data?.actions[0]?.payload.url,
            videoId: data?.actions[0]?.payload.info.video_id,
          });
          setPlaying(true);
        }
      }
    });
  }, [assistant, isSberPortal, setCurrentVideoInfo]);

  const handleGoHome = useCallback(() => {
    if (sendInfoReadyHome.current) return;
    playClick();
    sendInputOn();
    sendInfoReadyHome.current = true;
  }, [playClick, sendInputOn]);

  const handleVideoEnded = useCallback(() => {
    sendInputOn();
    taleEndReady.current = true;
    if (onVideoEnded) onVideoEnded();
  }, [onVideoEnded, sendInputOn]);

  const handleProgress: OnProgress = useCallback((state) => setPlayed(state.played), []);

  const focusedControl = useVideoControls(
    setPlaying,
    handleTogglePlaying,
    handleGoPrevVideo,
    handleGoNextVideo,
    handleGoHome,
    !paused && !isBlack,
  );

  useEffect(() => {
    setTimeout(() => {
      setCurrentVideoInfo((prev) => ({
        ...prev,
      }));
    }, 1000);
  }, [setCurrentVideoInfo]);

  useEffect(() => {
    return () => {
      setPlaying(false);
      setCurrentUrlIdx(-1);
      setIsBlack(false);
      setCurrentVideoInfo({
        src: '',
        videoId: undefined,
      });
    };
  }, [setCurrentVideoInfo]);

  return (
    <ScreenWrapper bg="black">
      {isBlack && <BlackFadeOut />}
      <Player
        url={currentVideoInfo.src}
        playing={playingActually}
        width="100%"
        height="100%"
        onProgress={handleProgress}
        onDuration={handleDuration}
        onEnded={handleVideoEnded}
        progressInterval={1000}
      />
      <Controls
        focusedControl={focusedControl}
        playing={playing}
        played={played}
        duration={duration}
        onTogglePlaying={handleTogglePlaying}
        onGoPrevVideo={handleGoPrevVideo}
        onGoNextVideo={handleGoNextVideo}
        onGoHome={handleGoHome}
      />
    </ScreenWrapper>
  );
};

export default React.memo(Video);

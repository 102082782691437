import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled, { css } from 'styled-components';

import helpIcon from 'assets/img/icons/help-icon.svg';
import clickSound from 'assets/sounds/btn-click.wav';
import helpSound from 'assets/sounds/help.mp3';

import { useAssistant, useDevice, useHelpButtonControls } from 'helpers/hooks';

const HlpButton = styled.button`
  height: 7vh;
  width: 7vh;

  background-image: url(${helpIcon});
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 80%;

  position: absolute;

  z-index: 11;
`;

const HelpButtonBackGround = styled.div`
  background: rgba(15, 232, 128, 0.35);
  border-radius: 50%;
  height: 7vh;
  position: absolute;
  width: 7vh;
  z-index: 12;
`;

const HelpButtonGlow = styled.div`
  background-color: #16b066;
  border-radius: 50%;
  box-shadow: 0 0 30px 20px #16b066, 0 0 5px 4px #16b066;
  height: 3vh;
  opacity: 0.7;
  position: absolute;
  top: 2vh;
  width: 3vh;
  z-index: 11;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 4vh;
  right: 6vw;
  display: flex;
  place-content: center;
  width: 7vh;
  height: 7vh;

  transition: opacity 500ms ease-in-out;
  &.helpButton-enter {
    opacity: 0;
  }
  &.helpButton-enter-done {
    opacity: 1;
  }
  &.helpButton-exit {
    opacity: 0;
  }
  &.helpButton-exit-done {
    opacity: 0;
  }
`;

interface Props {
  freezedControls: boolean;
  setFreezedControls: Function;
  currentScreen: string;
  animActive?: boolean;
  setTimeoutAction: React.Dispatch<
    React.SetStateAction<{
      timeout: number | undefined;
      time: number | null;
    }>
  >;
}

const HelpButton = ({ freezedControls, setFreezedControls, currentScreen, setTimeoutAction }: Props) => {
  const [animate, setAnimate] = useState(false);
  const [focused, setFocused] = useState<boolean>(false);
  const isSberPortal = useDevice();

  const click = useMemo(() => new Audio(clickSound), []);
  const help = useMemo(() => new Audio(helpSound), []);

  const assistant = useAssistant();

  useEffect(() => {
    setAnimate(true);
  }, []);

  useEffect(() => {
    setFocused(false);
  }, [currentScreen]);

  const playHelp = useCallback(() => help.play(), [help]);

  const onHelpEnd = useCallback(() => {
    setFreezedControls(false);
    setTimeoutAction((prev) => {
      if (prev.time === null) {
        return { ...prev };
      }
      return {
        ...prev,
        timeout: setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          assistant?.sendData({
            action: { action_id: 'timeout' },
            name: 'SERVER_ACTION',
          });
        }, prev.time),
      };
    });
    click.removeEventListener('ended', playHelp);
    help.removeEventListener('ended', onHelpEnd);
  }, [assistant, click, help, playHelp, setFreezedControls, setTimeoutAction]);

  const handleReply = useCallback(() => {
    if (freezedControls) return;
    setTimeoutAction((prev) => {
      clearTimeout(prev.timeout);
      return { ...prev, timeout: undefined };
    });
    setFreezedControls(true);
    click.play();

    click.addEventListener('ended', playHelp);
    help.addEventListener('ended', onHelpEnd);
  }, [click, freezedControls, help, onHelpEnd, playHelp, setFreezedControls, setTimeoutAction]);

  const helpFocused = useHelpButtonControls(handleReply, focused, setFocused, !freezedControls);

  return (
    <CSSTransition classNames="helpButton" timeout={0} in={animate}>
      <ButtonWrapper>
        {helpFocused && (
          <>
            <HelpButtonBackGround />
            <HelpButtonGlow />
          </>
        )}
        <HlpButton onClick={isSberPortal ? handleReply : undefined} />
      </ButtonWrapper>
    </CSSTransition>
  );
};
export default React.memo(HelpButton);

import React, { useEffect, useMemo, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

import { ScreenWrapper } from 'components/ui';

const ScreenWrapperTransition = styled(ScreenWrapper)<{ fade: boolean }>`
  opacity: ${({ fade }) => (fade ? 1 : 0)};
  transition: opacity 1000ms ease-in-out;

  &.bg-enter {
    opacity: 0;
  }
  &.bg-enter-done {
    opacity: 1;
  }
  &.bg-exit {
    opacity: 0;
  }
  &.bg-exit-done {
    opacity: 0;
  }
`;

type Props = {
  animActive: boolean;
  color: string;
};

const Gradient = ({ animActive, color }: Props) => {
  const [animate, setAnimate] = useState(false);
  const animIn = useMemo(() => (animActive === undefined ? animate : animate && animActive), [animActive, animate]);

  useEffect(() => {
    setAnimate(true);
    return () => setAnimate(false);
  }, []);

  return (
    <CSSTransition classNames="bg" timeout={0} in={animIn}>
      <ScreenWrapperTransition className="bg" fade={animIn} bg={color} popUp />
    </CSSTransition>
  );
};

export default React.memo(Gradient);

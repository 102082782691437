import { useState, useMemo, useCallback } from 'react';
import useEventListener from '@use-it/event-listener';
import debounce from 'lodash.debounce';

import useButtonsSounds from './useButtonsSounds';

type ControlsType = {
  [key: string]: {
    [key: string]: string | Function;
  };
};

const useVideoControls = (
  setPlaying: Function,
  onTogglePlaying: Function,
  onGoPrevVideo: Function,
  onGoNextVideo: Function,
  onGoHome: Function,
  active = true,
) => {
  const [controlFocus, setControlFocus] = useState<string | null>(null);

  const [playSwitch, playClick] = useButtonsSounds();

  const controls = useMemo<ControlsType>(
    () => ({
      pauseResume: {
        ArrowLeft: 'goPrev',
        ArrowRight: 'goNext',
        Enter: onTogglePlaying,
      },
      goPrev: {
        ArrowLeft: 'home',
        ArrowRight: 'pauseResume',
        Enter: onGoPrevVideo,
      },
      goNext: {
        ArrowLeft: 'pauseResume',
        ArrowRight: 'home',
        Enter: onGoNextVideo,
      },
      home: {
        ArrowLeft: 'goNext',
        ArrowRight: 'goPrev',
        Enter: onGoHome,
      },
    }),
    [onGoHome, onGoNextVideo, onGoPrevVideo, onTogglePlaying],
  );

  const handleControls = useCallback(
    ({ key }: KeyboardEvent) => {
      if (!active) return;

      if (controlFocus === null) {
        playSwitch();
        setPlaying(false);
        setControlFocus('pauseResume');
        return;
      }

      switch (key) {
        case 'Escape': {
          playSwitch();
          setControlFocus(null);
          setPlaying(true);
          return;
        }

        case 'Enter': {
          (controls[controlFocus].Enter as Function)();
          setControlFocus(null);
          break;
        }

        case 'ArrowLeft':
        case 'ArrowRight': {
          playSwitch();
          setControlFocus(controls[controlFocus][key] as string);
          break;
        }

        default:
          break;
      }
    },
    [active, controlFocus, controls, playSwitch, setPlaying],
  );

  const debouncedHandleControls = debounce(handleControls, 50);

  useEventListener('keyup', debouncedHandleControls);

  return controlFocus;
};

export default useVideoControls;

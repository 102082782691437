import { useState, useCallback } from 'react';

type UseLogs = () => [string, (msg: string) => void];

const useLogs: UseLogs = () => {
  const [logs, setLogs] = useState('');

  const log = useCallback((msg) => {
    setLogs((prevLogs) => `${prevLogs} ${msg}`);
    console.log(msg);
  }, []);

  return [logs, log];
};

export default useLogs;

import React, { RefObject, useState, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import { Lock } from 'components/ui';
import { getTime } from 'helpers/utils';

export const CardWrapper = styled.div`
  position: relative;
  height: 56.483vh;
  width: 36.298vh;

  border-radius: 2.222vh;
  overflow: hidden;

  display: flex;
  flex: 0 0 auto;
  flex-direction: column;

  background: rgba(255, 255, 255, 0.12);
  box-shadow: 0 1.481vh 4.4444vh rgba(0, 0, 0, 0.1);
`;

const CoverWrapper = styled.div`
  position: relative;
  height: 76%;
  overflow: hidden;
`;

const Cover = styled.div<{ coverUrl: string }>`
  width: 100%;
  height: 100%;

  background-image: url(${({ coverUrl }) => coverUrl});
  background-position: center center;
  background-size: cover;

  transition: transform 0.2s ease;
  transform: scale(1);

  z-index: 0;
`;

export const Badge = styled.span`
  font-weight: 500;
  font-size: 2.222vh;
  line-height: 2.969vh;
  text-align: center;
  letter-spacing: -0.022em;
  color: rgba(255, 255, 255, 0.56);

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 2.623%;
  right: 4.0825%;
  height: 10.4919%;
  width: 16.33%;

  border-radius: 100%;
  background-color: rgba(8, 8, 8, 0.56);

  z-index: 3;
`;

const Duration = styled.span`
  font-weight: 500;
  font-size: 1.852vh;
  line-height: 120%;
  letter-spacing: 0.0125em;

  position: absolute;
  left: 4.0816%;
  bottom: 3.4632%;

  padding: 1.023% 3.0618%;
  border-radius: 1.1111vh;
  backdrop-filter: blur(1.851vh);
  background-color: rgba(2, 19, 38, 0.32);

  z-index: 3;
`;

export const TitleWrapper = styled.div`
  padding: 6.125% 6.125% 0 6.125%;
  height: 18%;
  overflow: hidden;
  display: flex;
`;

const Title = styled.h3`
  font-weight: bold;
  font-size: 2.962vh;
  line-height: 130%;
  letter-spacing: -0.019em;
`;

const FocusWrapper = styled.div<{ focused?: boolean }>`
  padding: 0.37vh;
  border: 0.37vh solid transparent;
  border-radius: 2.592vh;

  transition: border 0.1s ease, transform 0.2s ease-in, opacity 500ms ease-in-out;

  &.card-enter,
  &.card-exit-done {
    opacity: 0;
  }
  &.card-enter-active {
    opacity: 1;
    transition: opacity 500ms;
  }
  &.card-exit-active {
    opacity: 0;
    transition: opacity 500ms;
  }
  &.card-enter-done,
  &.card-exit {
    opacity: 1;
  }

  ${({ focused }) =>
    focused &&
    css`
      border: 0.37vh solid ${({ theme }) => theme.colors.sberGreen};
    `}
`;

type Props = {
  outerRef?: RefObject<HTMLDivElement>;
  count?: number;
  title: string;
  duration?: number;
  imgSrc: string;
  locked?: boolean;
  focused?: boolean;
  animActive?: boolean;
  onClick?: () => void;
  className?: string;
};

const Card = ({ outerRef, count, title, duration, imgSrc, locked, onClick, animActive, focused, className }: Props) => {
  const [animate, setAnimate] = useState(false);
  useEffect(() => setAnimate(true), []);
  const timeout = useMemo(() => (count === undefined ? 0 : count - 1) * 120, [count]);
  const animIn = useMemo(() => (animActive === undefined ? animate : animate && animActive), [animActive, animate]);

  return (
    <CSSTransition classNames="card" timeout={timeout} in={animIn}>
      <FocusWrapper className={className} focused={focused}>
        <CardWrapper ref={outerRef} onClick={onClick}>
          {count !== undefined && <Badge>{count}</Badge>}

          <CoverWrapper>
            {locked && <Lock />}
            {duration !== undefined && <Duration>{getTime(duration)}</Duration>}
            <Cover coverUrl={imgSrc} />
          </CoverWrapper>

          <TitleWrapper>
            <Title>{title}</Title>
          </TitleWrapper>
        </CardWrapper>
      </FocusWrapper>
    </CSSTransition>
  );
};

export default React.memo(Card);

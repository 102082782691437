import React, { ReactNode, useMemo } from 'react';
import { DeviceContext } from 'helpers/contexts';

type Props = {
  children: ReactNode;
};

const DeviceProvider = ({ children }: Props) => {
  const isSberPortal = useMemo(() => window.navigator.userAgent.includes('stargate'), []);
  return <DeviceContext.Provider value={isSberPortal}>{children}</DeviceContext.Provider>;
};

export default DeviceProvider;

import { createGlobalStyle, keyframes as kf } from 'styled-components';

export const GlobalStyle = createGlobalStyle`


  * {
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    box-sizing: border-box;
  }



  #root {
    width: 100vw;
    height: 100vh;
  }
`;

// Project colors

const colors = {
  sberGreen: '#2ac673',
  sberBlueRGBA: '0, 151, 216, 0.69',
  sberBlueRGB: '0, 151, 216',
  sberPurpleRGBA: '217, 193, 255, 0.92',
  sberPurpleRGB: '217, 193, 255',
  sberCyan: '#BBFBFF',
  sberGold: '#ffd600',
  sberGoldRGB: '255, 214, 0',
};

// Keyframe animations

const keyframes = {
  dropShadowRippleBlue: kf`
    0% {
      filter: drop-shadow(0 3.7vw 5.6vh rgba(${colors.sberBlueRGBA}));
    }
    50% {
      filter: drop-shadow(0 0.7vw 3vh rgba(${colors.sberBlueRGB}, 0));
    }
    100% {
      filter: drop-shadow(0 3.7vw 5.6vh rgba(${colors.sberBlueRGBA}));
    }
  `,
  dropShadowRipplePurple: kf`
    0% {
      filter: drop-shadow(0 2.8vh 13vh rgba(${colors.sberPurpleRGBA}));
    }
    50% {
      filter: drop-shadow(0 7vh 3vh rgba(${colors.sberPurpleRGB}, 0));
    }
    100% {
      filter: drop-shadow(0 2.8vh 13vh rgba(${colors.sberPurpleRGBA}));
    }
  `,
  dropShadowRippleGold: kf`
    0% {
      filter: drop-shadow(0 0.74vh 7.407vh rgba(${colors.sberGoldRGB}, 1));
    }
    50% {
      filter: drop-shadow(0 0 1vh rgba(${colors.sberGoldRGB}, 0));
    }
    100% {
      filter: drop-shadow(0 0.74vh 7.407vh rgba(${colors.sberGoldRGB}, 1));
    }
  `,
};

// Cubic Bezier animations

const cubicBezier = {
  bounceIn: 'cubic-bezier(0.175, 0.885, 0.32, 1.275)',
  bounceOut: 'cubic-bezier(0.5, -0.26, 0.57, -0.31)',
};

export const theme = {
  colors,
  animations: {
    keyframes,
    cubicBezier,
  },
};

import React from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import pauseIcon from 'assets/img/icons/pause-icon.svg';
import resumeIcon from 'assets/img/icons/resume-icon.svg';

import ControlButton, { Icon, Props as ControlProps } from './ControlButton';

const Button = styled(ControlButton)`
  height: 12.962vh;
  width: 12.962vh;

  ${Icon} {
    position: absolute;

    height: 0;
    opacity: 0;

    &.icon-enter-done {
      height: 42.366%;
      opacity: 1;
      transition: height 0.2s ${({ theme }) => theme.animations.cubicBezier.bounceIn};
    }

    &.icon-exit {
      opacity: 1;
      height: 42.366%;
    }

    &.icon-exit-done {
      height: 0;
      opacity: 0;
      transition: height 0.2s ${({ theme }) => theme.animations.cubicBezier.bounceOut};
    }
  }
`;

type Props = {
  playing: boolean;
} & ControlProps;

const PauseResumeButton = ({ playing, ...restProps }: Props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Button {...restProps}>
    <>
      <CSSTransition classNames="icon" timeout={0} appear={playing} in={playing}>
        <Icon src={pauseIcon} />
      </CSSTransition>

      <CSSTransition classNames="icon" timeout={0} appear={!playing} in={!playing}>
        <Icon src={resumeIcon} />
      </CSSTransition>
    </>
  </Button>
);

export default React.memo(PauseResumeButton);

import React, { useState, useEffect, useMemo, useCallback, createRef, RefObject } from 'react';
import styled, { css } from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import { OPTION_DURATION, OPTION_DELAY, OPTION_TOTAL } from 'helpers/constants';
import { useAssistant, useCardsControls, useDevice } from 'helpers/hooks';
import Card, { TitleWrapper, CardWrapper, Badge } from 'components/ui/Card';
import { ScreenWrapper } from 'components/ui';
import { QuestionPopUpProps } from 'helpers/hooks/usePopUp';
import clickSound from 'assets/sounds/btn-click.wav';

import Reactions from './Reactions';

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  position: absolute;
  top: 25.185vh;
  width: 100%;

  > *:not(:last-child) {
    margin-right: 1.851vh;
  }
`;

const Option = styled(Card)<{ answer: boolean | null }>`
  ${({ answer }) =>
    answer !== null &&
    css`
      border-color: transparent !important;
      opacity: 1;
      transform: translateY(0);
    `}

  ${Badge} {
    visibility: hidden;
  }

  ${CardWrapper} {
    position: relative;
    transition: left ${OPTION_DURATION}ms cubic-bezier(0.38, 0.97, 0.63, 1.13) ${OPTION_DELAY}ms;
    left: 0;
  }

  ${TitleWrapper} {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

const ScreenWrapperTransition = styled(ScreenWrapper)<{ fade: boolean }>`
  opacity: 1;
  transition: opacity 500ms ease-in-out;

  &.bg-enter {
    opacity: 1;
  }
  &.bg-enter-done {
    opacity: 1;
  }
  &.bg-exit {
    opacity: 0;
  }
  &.bg-exit-done {
    opacity: 0;
  }
`;

const centerCorrectAnswer = (ref?: RefObject<HTMLDivElement>) => {
  if (!ref?.current) return;
  const screenWidth = window.innerWidth;
  const { width, left } = ref.current.getBoundingClientRect();
  // eslint-disable-next-line no-param-reassign
  ref.current.style.left = `${(100 * (screenWidth / 2 - left - width / 2)) / screenWidth}vw`;
};

export type Options = {
  cover: string;
  title: string;
  payload: any;
  isCorrect: boolean;
  ref?: RefObject<HTMLDivElement>;
}[];

type Props = {
  animActive?: boolean;
  popUpProps: QuestionPopUpProps;
  freezedControls: boolean;
  fadeAnimation: { in: boolean; start: boolean };
};

const Question = ({ animActive, popUpProps, freezedControls, fadeAnimation }: Props) => {
  const [answer, setAnswer] = useState<boolean | null>(null);
  const [animate, setAnimate] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);
  const [fade, setFade] = useState<boolean>(fadeAnimation.in && !fadeAnimation.start);
  const animIn = useMemo(() => (animActive === undefined ? animate : animate && animActive), [animActive, animate]);
  const isSberPortal = useDevice();
  const [options] = useState(
    popUpProps?.questionOptions.map((option) => ({ ...option, ref: createRef<HTMLDivElement>() })),
  );
  const assistant = useAssistant();
  const click = useMemo(() => new Audio(clickSound), []);

  const handleOnAnswer = useCallback(
    (idx: number) => {
      if (answer !== null || freezedControls) return;
      click.play();
      if (assistant) {
        assistant.sendData({
          action: { action_id: 'answerEvent', parameters: options[isSberPortal ? idx : id].payload },
          name: 'SERVER_ACTION',
        });
      }
    },
    [answer, assistant, click, freezedControls, id, isSberPortal, options],
  );

  const [cardFocusedIdx] = useCardsControls(options.length, handleOnAnswer, !freezedControls && animActive);

  useEffect(() => {
    setId(cardFocusedIdx);
  }, [cardFocusedIdx]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    assistant?.on('data', (data: any) => {
      if ('actions' in data) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        data?.actions.forEach((action: any) => {
          if (
            action?.type === 'SHOW_MESSAGE' &&
            (action?.payload?.type === 'QUESTION_CORRECT' ||
              action?.payload?.type === 'QUESTION_WRONG' ||
              action?.payload?.type === 'QUESTION_WRONG_ENDS' ||
              action?.payload?.type === 'QUESTION_CORRECT_ENDS')
          ) {
            setAnswer(
              action?.payload?.type === 'QUESTION_CORRECT' || action?.payload?.type === 'QUESTION_CORRECT_ENDS',
            );
            setAnimate(false);
            const correctAnswer = options.find((option) => option.isCorrect);
            centerCorrectAnswer(correctAnswer?.ref);
          }
        });
      }
    });
  }, [assistant, options]);

  useEffect(() => setFade(fadeAnimation.in), [fadeAnimation]);
  useEffect(() => setAnimate(true), []);

  return (
    <CSSTransition classNames="bg" timeout={0} in={fade}>
      <ScreenWrapperTransition
        fade={fade}
        className="bg"
        bg="linear-gradient(0deg, rgba(39, 0, 149, 0.3), rgba(39, 0, 149, 0.3))"
        popUp
      >
        <OptionsWrapper>
          {options.map(({ cover, title, ref, isCorrect }, idx) => (
            <Option
              key={title}
              outerRef={ref}
              imgSrc={cover}
              title={title}
              count={idx + 1}
              answer={answer}
              focused={!isSberPortal && cardFocusedIdx === idx}
              animActive={animIn || isCorrect}
              onClick={() => (isSberPortal ? handleOnAnswer(idx % options.length) : undefined)}
            />
          ))}
        </OptionsWrapper>

        <Reactions active={answer !== null} correct={answer === true} timeout={OPTION_TOTAL} />
      </ScreenWrapperTransition>
    </CSSTransition>
  );
};

export default React.memo(Question);

import { useCallback } from 'react';
import useEventListener from '@use-it/event-listener';
import debounce from 'lodash.debounce';

import switchSound from 'assets/sounds/btn-switch.wav';

const useHelpButtonControls = (onGetHelp: Function, focused: boolean, setFocused: Function, active = true) => {
  const switchSnd = new Audio(switchSound);

  const handleControls = useCallback(
    ({ key }: KeyboardEvent) => {
      if (!active) return;

      switch (key) {
        case 'Enter':
          if (focused) onGetHelp();
          break;

        case 'ArrowLeft':
          setFocused(false);
          break;

        case 'ArrowRight':
          setFocused(false);
          break;

        case 'ArrowUp':
          if (focused === false) {
            switchSnd.play();
            setFocused(true);
          }
          break;

        case 'ArrowDown':
          if (focused === true) {
            switchSnd.play();
            setFocused(false);
          }
          break;

        default:
          break;
      }
    },
    [active, focused, onGetHelp, setFocused, switchSnd],
  );

  const debouncedHandleControls = debounce(handleControls, 50);

  useEventListener('keyup', debouncedHandleControls);

  return focused;
};

export default useHelpButtonControls;

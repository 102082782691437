import { useState, useCallback } from 'react';
import {
  STICKER_TIMEOUT,
  CONFIRM_TIMEOUT,
  QUESTION_CORRECT_TIMEOUT,
  QUESTION_INCORRECT_TIMEOUT,
} from 'helpers/constants';
import { Options } from 'components/question/Question';

const popUpTimeouts = {
  BUBBLE: STICKER_TIMEOUT,
  CONFIRM_BUBBLE: CONFIRM_TIMEOUT,
  QUESTION: {
    correct: QUESTION_CORRECT_TIMEOUT,
    incorrect: QUESTION_INCORRECT_TIMEOUT,
  },
};

export type NoPopUp = {
  type: string;
};
export type StickerPopUpProps = {
  type: string;
  imageId: string;
  text?: string[];
};
export type ConfirmPopUpProps = {
  type: string;
  imageId: string;
  yesEvent: string;
  noEvent: string;
  text?: string;
  keyboard: object;
};
export type QuestionPopUpProps = {
  type: string;
  questionOptions: Options;
};

export type PopUpProps = StickerPopUpProps | ConfirmPopUpProps | QuestionPopUpProps | NoPopUp;

type SetVideoPaused = (paused: boolean) => void;
export type OpenPopUp = (popUpProps: PopUpProps) => void;
export type ClosePopUp = (name: keyof typeof popUpTimeouts, delay?: number) => void;
type UsePopUp = (setVideoPaused: SetVideoPaused) => [PopUpProps, boolean, OpenPopUp, ClosePopUp];

const usePopUp: UsePopUp = (setVideoPaused) => {
  const [currentPopUp, setCurrentPopUp] = useState<PopUpProps>({
    type: 'null',
  });
  const [popUpAnimated, setPopUpAnimated] = useState<boolean>(false);

  const openPopUp: OpenPopUp = useCallback(
    (popUpProps) => {
      const popUpChange = new Promise<void>((res) => {
        setPopUpAnimated(false);
        setCurrentPopUp({ type: 'null' });
        res();
      });
      popUpChange.then(() => {
        setVideoPaused(true);
        setCurrentPopUp(popUpProps);
        setPopUpAnimated(true);
      });
    },
    [setVideoPaused],
  );

  const closePopUp: ClosePopUp = useCallback(
    (type, _delay) => {
      setPopUpAnimated(false);

      let delay = 0;

      if (type === 'QUESTION') {
        delay = _delay || popUpTimeouts.QUESTION.incorrect;
      } else {
        delay = popUpTimeouts[type];
      }

      setTimeout(() => {
        setCurrentPopUp({ type: 'null' });
        setVideoPaused(false);
      }, delay);
    },
    [setVideoPaused],
  );

  return [currentPopUp, popUpAnimated, openPopUp, closePopUp];
};

export default usePopUp;

import useSound from 'use-sound';

import switchSound from 'assets/sounds/btn-switch.wav';
import clickSound from 'assets/sounds/btn-click.wav';

const useButtonsSounds = () => {
  const [playSwitch] = useSound(switchSound);
  const [playClick] = useSound(clickSound);

  return [playSwitch, playClick];
};

export default useButtonsSounds;

import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';

import App from 'components/App';
import { GlobalStyle, theme } from 'helpers/themes';

import * as serviceWorker from './serviceWorker';
import './index.css';

ReactDOM.render(
  <>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>

    <GlobalStyle />
  </>,
  document.getElementById('root'),
);

serviceWorker.unregister();

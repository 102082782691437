import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ProgressiveImage from 'react-progressive-image';

import { onImagesLoad, onSoundsLoad } from 'helpers/utils';
import { ScreenWrapper } from 'components/ui';
import Sticker, { Image } from 'components/ui/Sticker';

import welcomeSticker from 'assets/img/stickers/welcome-sticker.webp';
import welcomeBg from 'assets/img/backgrounds/welcome-bg.webp';
import welcomeBgPrev from 'assets/img/backgrounds/welcome-bg-preview.webp';
import homeBg from 'assets/img/backgrounds/home-bg.webp';
import homeBgPrev from 'assets/img/backgrounds/home-bg-preview.webp';
import correctGif from 'assets/img/gifs/correct.webp';
import incorrectGif from 'assets/img/gifs/incorrect.webp';
import confirmIcon from 'assets/img/icons/confirm-icon.webp';
import homeIcon from 'assets/img/icons/home-icon.svg';
import lockIcon from 'assets/img/icons/lock-icon.webp';
import nextIcon from 'assets/img/icons/next-icon.svg';
import pauseIcon from 'assets/img/icons/pause-icon.svg';
import prevIcon from 'assets/img/icons/prev-icon.svg';
import rejectIcon from 'assets/img/icons/reject-icon.webp';
import resumeIcon from 'assets/img/icons/resume-icon.svg';
import helpIcon from 'assets/img/icons/help-icon.svg';

import bearSmile from 'assets/img/stickers/bearSmile.webp';
import bearThink from 'assets/img/stickers/bearThink.webp';
import bed from 'assets/img/stickers/bed.webp';
import bye from 'assets/img/stickers/bye.webp';
import cocky from 'assets/img/stickers/cocky.webp';
import dream from 'assets/img/stickers/dream.webp';
import glad from 'assets/img/stickers/glad.webp';
import hi from 'assets/img/stickers/hi.webp';
import idea from 'assets/img/stickers/idea.webp';
import idle from 'assets/img/stickers/idle.webp';
import idleSmile from 'assets/img/stickers/idleSmile.webp';
import idleStand from 'assets/img/stickers/idleStand.webp';
import nightCap from 'assets/img/stickers/nightCap.webp';
import smile from 'assets/img/stickers/smile.webp';
import sorry from 'assets/img/stickers/sorry.webp';
import think from 'assets/img/stickers/think.webp';
import what from 'assets/img/stickers/what.webp';
import whatReverse from 'assets/img/stickers/whatReverse.webp';
import payment from 'assets/img/stickers/payment.webp';
import textBubble from 'assets/img/stickers/textBubble.webp';

import mainTheme from 'assets/sounds/main-theme.mp3';
import endTheme from 'assets/sounds/end-theme.mp3';

const urls = [
  welcomeSticker,
  homeBg,
  homeBgPrev,
  correctGif,
  incorrectGif,
  confirmIcon,
  homeIcon,
  lockIcon,
  nextIcon,
  pauseIcon,
  prevIcon,
  rejectIcon,
  resumeIcon,
  helpIcon,

  bearSmile,
  bearThink,
  bed,
  bye,
  cocky,
  dream,
  glad,
  hi,
  idea,
  idle,
  idleSmile,
  idleStand,
  nightCap,
  smile,
  sorry,
  think,
  what,
  whatReverse,
  payment,
  textBubble,
];

const soundUrls = [mainTheme, endTheme];

const WelcomeSticker = styled(Sticker)`
  background: transparent;

  ${Image} {
    filter: drop-shadow(0 2.8vh 13vh rgba(217, 193, 255, 0.92));
    animation: ${({ theme }) => theme.animations.keyframes.dropShadowRipplePurple} 4s infinite;
  }
`;

const Welcome = () => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
    onSoundsLoad(soundUrls, () => {
      onImagesLoad(urls, () => {
        // make 1.5s pause for better experience
        setTimeout(() => {
          setAnimate(false);
        }, 1500);
      });
    });
    return () => setAnimate(false);
  }, []);

  return (
    <ProgressiveImage src={welcomeBg} placeholder={welcomeBgPrev}>
      {(src: string) => (
        <ScreenWrapper bg={`url(${src}) #704be9`}>
          <WelcomeSticker
            height="81.111vh"
            imgSrc={welcomeSticker}
            animActive={animate}
            popUpFadeAnimation={{ in: true, start: true }}
          />
        </ScreenWrapper>
      )}
    </ProgressiveImage>
  );
};

export default React.memo(Welcome);

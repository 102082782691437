import { useState, useCallback } from 'react';
import useEventListener from '@use-it/event-listener';
import debounce from 'lodash.debounce';

import { HandleReply } from 'components/Confirm';
import switchSound from 'assets/sounds/btn-switch.wav';

const useConfirmControls = (onReply: HandleReply, active = true) => {
  const [reply, setReply] = useState<boolean | null>(null);
  const switchSnd = new Audio(switchSound);

  const handleControls = useCallback(
    ({ key }: KeyboardEvent) => {
      if (!active) return;

      switch (key) {
        case 'Enter':
          if (reply === null) return;
          onReply(reply);
          setReply(null);
          break;

        case 'ArrowLeft':
          switchSnd.play();
          if (reply === null) setReply(true);
          else setReply(!reply);
          break;

        case 'ArrowRight':
          switchSnd.play();
          if (reply === null) setReply(false);
          else setReply(!reply);
          break;

        case 'ArrowUp':
          if (reply !== null) {
            setReply(null);
          }
          break;

        case 'ArrowDown':
          if (reply === null) {
            setReply(true);
          }
          break;

        default:
          break;
      }
    },
    [active, reply, onReply, switchSnd],
  );

  const debouncedHandleControls = debounce(handleControls, 50);

  useEventListener('keyup', debouncedHandleControls);

  return reply;
};

export default useConfirmControls;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import correctGif from 'assets/img/gifs/correct.webp';
import incorrectGif from 'assets/img/gifs/incorrect.webp';
import { CORRECT_ANS_ANIM_DURATION, INCORRECT_ANS_ANIM_DURATION } from 'helpers/constants';

const Reaction = styled.img`
  height: 70vh;
  position: absolute;
  bottom: 0;
`;

const Correct = styled(Reaction)`
  right: 0;
`;

const Incorrect = styled(Reaction)`
  left: 0;
`;

type Props = {
  active: boolean;
  correct: boolean;
  timeout?: number;
};

const Reactions = ({ active, correct, timeout }: Props) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (active) {
      setTimeout(
        () => {
          setAnimate(true);
          setTimeout(() => setAnimate(false), correct ? CORRECT_ANS_ANIM_DURATION : INCORRECT_ANS_ANIM_DURATION);
        },
        timeout === undefined ? 0 : timeout,
      );
    } else {
      setAnimate(false);
    }
    return () => setAnimate(false);
  }, [active, timeout, correct]);

  return animate ? (
    <>
      {correct ? (
        <Correct src={correctGif} alt="Радостный Ам ням, показывающий на правильный ответ" />
      ) : (
        <Incorrect src={incorrectGif} alt="Поучающий Ам ням, показывающий на правильный ответ" />
      )}
    </>
  ) : null;
};

export default React.memo(Reactions);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import { ScreenWrapper } from 'components/ui';
import { STICKER_DURATION } from 'helpers/constants';

export const Image = styled.img<{ height: string }>`
  filter: drop-shadow(0 3.7vw 5.6vh ${({ theme }) => `rgba(${theme.colors.sberBlueRGBA})`});

  animation: ${({ theme }) => theme.animations.keyframes.dropShadowRippleBlue} 4s infinite;

  height: ${({ height }) => height};
  transform: scale(0);

  will-change: transform;
  &.sticker-enter-done {
    transform: scale(1);
    filter: drop-shadow(0 3.7vw 5.6vh ${({ theme }) => `rgba(${theme.colors.sberBlueRGBA})`});
    transition: transform ${STICKER_DURATION}ms ${({ theme }) => theme.animations.cubicBezier.bounceIn};
  }

  &.sticker-exit {
    transform: scale(1);
  }

  &.sticker-exit-done {
    transform: scale(0);
    transition: transform ${STICKER_DURATION}ms ${({ theme }) => theme.animations.cubicBezier.bounceOut};
  }
`;

const Text = styled.div`
  font-size: 4vh;
  letter-spacing: -0.019em;
  position: absolute;
  top: 30vh;
  width: 35vw;
  z-index: 1;

  & p {
    color: #5d5d5d;
    font-weight: 500;
    text-align: center;
  }
  & p:last-child {
    font-weight: 700;
    margin: 5vw auto auto;
    width: 30vw;
  }
  & p:only-child {
    margin-top: 10vh;
  }

  transform: scale(0);
  &.text-enter-done {
    transform: scale(1);
    transition: transform ${STICKER_DURATION}ms ${({ theme }) => theme.animations.cubicBezier.bounceIn};
  }

  &.text-exit {
    transform: scale(1);
  }

  &.text-exit-done {
    transform: scale(0);
    transition: transform 750ms ${({ theme }) => theme.animations.cubicBezier.bounceOut};
  }
`;

const ScreenWrapperTransition = styled(ScreenWrapper)<{ fade?: boolean }>`
  opacity: ${({ fade }) => (fade ? 1 : 0)};
  transition: opacity 500ms ease-in-out;

  &.bg-enter {
    opacity: 0;
  }
  &.bg-enter-done {
    opacity: 1;
  }
  &.bg-exit {
    opacity: 0;
  }
  &.bg-exit-done {
    opacity: 0;
  }
`;

type Props = {
  imgSrc: string;
  height?: string;
  className?: string;
  animActive?: boolean;
  onLoad?: () => void;
  popUpFadeAnimation: { in: boolean; start: boolean };
  text?: string[];
};

const Sticker = ({ imgSrc, className, height, animActive, onLoad, popUpFadeAnimation, text }: Props) => {
  const [animate, setAnimate] = useState(false);
  const [fade, setFade] = useState<boolean>(popUpFadeAnimation?.in && !popUpFadeAnimation?.start);
  const animIn = useMemo(() => (animActive === undefined ? animate : animate && animActive), [animActive, animate]);

  useEffect(() => {
    return () => setAnimate(false);
  }, []);

  useEffect(() => setFade(popUpFadeAnimation?.in), [popUpFadeAnimation]);

  return (
    <CSSTransition classNames="bg" timeout={0} in={fade}>
      <ScreenWrapperTransition
        fade={fade}
        className={className}
        bg="linear-gradient(0deg, rgba(39, 0, 149, 0.3), rgba(39, 0, 149, 0.3))"
        popUp
      >
        <CSSTransition classNames="text" timeout={0} in={animIn}>
          <>
            {(imgSrc?.includes('payment') || imgSrc?.includes('textBubble')) && (
              <Text>{text?.map((sentence) => sentence !== '' && <p key={sentence}>{sentence}</p>)}</Text>
            )}
          </>
        </CSSTransition>
        <CSSTransition classNames="sticker" timeout={0} in={animIn}>
          <Image
            src={imgSrc}
            height={height === undefined ? '80vh' : height}
            onLoad={() => onLoad?.() || setAnimate(true)}
          />
        </CSSTransition>
      </ScreenWrapperTransition>
    </CSSTransition>
  );
};

export default React.memo(Sticker);

import { useState, useCallback } from 'react';
import debounce from 'lodash.debounce';
import useEventListener from '@use-it/event-listener';

import useButtonsSounds from './useButtonsSounds';

type OnCardClick = (id: number) => void;

const useCardsControls = (cardsNum: number, onCardClick: OnCardClick | undefined, active = true) => {
  const [cardIdx, setCardIdx] = useState<number | null>(0);
  const [prevCardIdx, setPrevCardIdx] = useState<number>(0);

  const [playSwitch, playClick] = useButtonsSounds();

  const handleControls = useCallback(
    ({ key }: KeyboardEvent) => {
      if (!active) return;

      switch (key) {
        case 'Enter':
          if (cardIdx !== null) {
            if (onCardClick) onCardClick(cardIdx);
          }
          break;

        case 'ArrowRight':
          playSwitch();
          if (cardIdx === null) {
            setCardIdx(cardsNum - 1);
          } else {
            setCardIdx((cardIdx + 1) % cardsNum);
          }
          break;

        case 'ArrowLeft':
          playSwitch();
          if (cardIdx === null) {
            setCardIdx(0);
          } else {
            setCardIdx((cardIdx + cardsNum - 1) % cardsNum);
          }
          break;

        case 'ArrowUp':
          if (cardIdx !== null) {
            setPrevCardIdx(cardIdx);
            setCardIdx(null);
          }
          break;

        case 'ArrowDown':
          if (cardIdx === null) {
            setCardIdx(prevCardIdx);
          }
          break;

        default:
          break;
      }
    },
    [active, cardIdx, playSwitch, onCardClick, cardsNum, prevCardIdx],
  );
  const debouncedHandleControls = debounce(handleControls, 50);
  useEventListener('keyup', debouncedHandleControls);

  return [cardIdx, prevCardIdx] as [number, number];
};

export default useCardsControls;

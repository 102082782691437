/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

const ScreenWrapper = styled.main<{ bg?: string; popUp?: boolean }>`
  height: 100%;
  width: 100%;
  background: ${({ bg }) => bg};
  background-position: center center;
  background-size: cover;

  transition: filter 500ms ease;

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ popUp }) =>
    popUp &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
    `}
`;

export default ScreenWrapper;

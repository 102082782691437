import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import { useAssistant, useConfirmControls, useDevice } from 'helpers/hooks';
import { ConfirmPopUpProps } from 'helpers/hooks/usePopUp';

import confirmIcon from 'assets/img/icons/confirm-icon.webp';
import rejectIcon from 'assets/img/icons/reject-icon.webp';
import clickSound from 'assets/sounds/btn-click.wav';

import { Sticker, ScreenWrapper } from './ui';

const ActionButtonsWrapper = styled.div<{ payment: boolean }>`
  position: absolute;
  top: ${({ payment }) => (payment ? '42vh' : '45.74vh')};

  display: flex;
  justify-content: space-between;

  z-index: 25;

  > *:first-child {
    margin-right: ${({ payment }) => (payment ? '70vh' : '45.74vh')};
  }
`;

const ActionButton = styled.button<{ focused: boolean; buttonsVisible?: boolean }>`
  height: 15vh;
  width: 15vh;
  border-radius: 50%;
  background-color: white;

  border: 0.925vh solid ${({ theme }) => theme.colors.sberCyan};
  box-shadow: 0 3.703vh 5.555vh ${({ theme }) => `rgba(${theme.colors.sberBlueRGBA})`};

  background-repeat: no-repeat;
  background-position: center center;
  background-size: 80%;

  transition: box-shadow 0.2s ease, border 0.3s ease, background-size 0.2s ease-out;

  ${({ focused }) =>
    focused &&
    css`
      background-size: 88%;
    `}

  :active {
    background-size: 80%;
  }

  transform: ${({ buttonsVisible }) => (buttonsVisible ? 'scale(1)' : 'scale(0)')};
  &.actionBtn-enter {
    transform: scale(0);
  }

  &.actionBtn-enter-done {
    transform: scale(1);
    transition: box-shadow 0.2s ease, border 0.3s ease, background-size 0.2s ease-out,
      transform 0.3s ${({ theme }) => theme.animations.cubicBezier.bounceIn};
  }

  &.actionBtn-exit {
    transform: scale(1);
  }

  &.actionBtn-exit-done {
    transform: scale(0);
    transition: box-shadow 0.2s ease, border 0.3s ease, background-size 0.2s ease-out,
      transform 0.3s ${({ theme }) => theme.animations.cubicBezier.bounceOut};
  }
`;

const ConfirmButton = styled(ActionButton)`
  background-image: url(${confirmIcon});

  ${({ focused }) =>
    focused &&
    css`
      border: 0.925vh solid #9aff92;
      box-shadow: 0 0 0 2.962vh #42e195, 0 6.666vh 8.518vh rgba(0, 200, 80, 0.22);
    `}
`;

const RejectButton = styled(ActionButton)`
  background-image: url(${rejectIcon});

  ${({ focused }) =>
    focused &&
    css`
      border: 0.925vh solid #ffbea3;
      box-shadow: 0 0 0 2.962vh #ff8780, 0 6.666vh 8.518vh rgba(255, 65, 65, 0.44);
    `}
`;

export type HandleReply = (confirmed: boolean) => void;

type Props = {
  stickerSrc: string;
  animActive?: boolean;
  onReply?: (confirmed: boolean) => void;
  popUpProps: ConfirmPopUpProps;
  freezedControls: boolean;
  popUpFadeAnimation: { in: boolean; start: boolean };
};

const Confirm = ({ stickerSrc, animActive, popUpProps, freezedControls, popUpFadeAnimation }: Props) => {
  const [animate, setAnimate] = useState(false);
  const assistant = useAssistant();
  const animIn = useMemo(() => (animActive === undefined ? animate : animate && animActive), [animActive, animate]);
  const isSberPortal = useDevice();
  const [buttonsVisible, setButtonsVisible] = useState(false);
  const [remoteReply, setRemoteReply] = useState<boolean | null>(null);
  const [answered, setAnswered] = useState<boolean>(false);

  const click = useMemo(() => new Audio(clickSound), []);

  useEffect(() => {
    setButtonsVisible(animIn);
  }, [animIn]);

  const handleReply: HandleReply = useCallback(
    (confirmed: boolean) => {
      if (freezedControls) return;
      setAnswered(true);
      click.play();
      if (assistant) {
        assistant.sendData({
          action: {
            action_id: `${(isSberPortal ? confirmed : remoteReply) ? popUpProps?.yesEvent : popUpProps?.noEvent}`,
          },
          name: 'SERVER_ACTION',
        });
      }
    },
    [assistant, click, freezedControls, isSberPortal, popUpProps.noEvent, popUpProps.yesEvent, remoteReply],
  );

  const reply = useConfirmControls(handleReply, !freezedControls && animIn && !answered);

  useEffect(() => {
    setRemoteReply(reply);
  }, [reply]);

  useEffect(() => {
    return () => {
      setAnimate(false);
      setAnswered(false);
    };
  }, []);

  return (
    <ScreenWrapper popUp>
      <Sticker
        imgSrc={stickerSrc}
        animActive={animIn}
        onLoad={() => setAnimate(true)}
        popUpFadeAnimation={popUpFadeAnimation}
        text={popUpProps.text?.split('\n')}
      />

      <ActionButtonsWrapper payment={stickerSrc?.includes('payment') || stickerSrc?.includes('textBubble')}>
        <CSSTransition classNames="actionBtn" timeout={0} in={animIn}>
          <ConfirmButton
            onClick={() => (isSberPortal ? handleReply(true) : undefined)}
            focused={reply === true && !isSberPortal}
            buttonsVisible={buttonsVisible}
          />
        </CSSTransition>

        <CSSTransition classNames="actionBtn" timeout={200} in={animIn}>
          <RejectButton
            onClick={() => (isSberPortal ? handleReply(false) : undefined)}
            focused={reply === false && !isSberPortal}
            buttonsVisible={buttonsVisible}
          />
        </CSSTransition>
      </ActionButtonsWrapper>
    </ScreenWrapper>
  );
};

export default React.memo(Confirm);

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { useAssistant, useDebounce, useLogs } from 'helpers/hooks';
import { OpenPopUp, PopUpProps, ClosePopUp } from 'helpers/hooks/usePopUp';
import { CardItem } from 'components/Home';
import clickSound from 'assets/sounds/btn-click.wav';

const Logs = styled.div`
  position: fixed;
  top: 2vh;
  left: 2vh;
  right: 2vh;
  z-index: 9999;
  padding: 1vh;
  background: white;
  word-break: break-word;
`;

interface Props {
  openPopUp: OpenPopUp;
  closePopUp: ClosePopUp;
  setCurrentScreen: React.Dispatch<React.SetStateAction<string>>;
  setCardsList: React.Dispatch<React.SetStateAction<CardItem[]>>;
  setFreezedControls: React.Dispatch<React.SetStateAction<boolean>>;
  setFadeAnimation: React.Dispatch<React.SetStateAction<{ in: boolean; start: boolean }>>;
  setPopUpFadeAnimation: React.Dispatch<React.SetStateAction<{ in: boolean; start: boolean }>>;
  handleGoHome: () => void;
  setTimeoutAction: React.Dispatch<
    React.SetStateAction<{
      timeout: number | undefined;
      time: number | null;
    }>
  >;
  setHelpVisible: React.Dispatch<React.SetStateAction<boolean>>;
  inputOnTimeoutStart: Function;
  setInputOnTimeout: React.Dispatch<React.SetStateAction<number | undefined>>;
}

type EventQueue = { popUpQueue: any[]; backgroundQueue: any[]; audioQueue: Array<HTMLAudioElement[]> };

const EventController = ({
  openPopUp,
  closePopUp,
  setCurrentScreen,
  setCardsList,
  setFreezedControls,
  setFadeAnimation,
  setPopUpFadeAnimation,
  handleGoHome,
  setTimeoutAction,
  setHelpVisible,
  inputOnTimeoutStart,
  setInputOnTimeout,
}: Props) => {
  const [eventQueue, setEventQueue] = useState<EventQueue>({
    popUpQueue: [],
    backgroundQueue: [],
    audioQueue: [],
  });
  const lastEvent = useRef<number>(0);
  const queueLength = useRef<number>(0);
  const goHomeReady = useRef<boolean>(false);
  const [backButtonTrigger, setBackButtonTrigger] = useState<boolean>(false);

  const click = useMemo(() => new Audio(clickSound), []);

  const assistant = useAssistant();
  const [logs, log] = useLogs();
  const history = useHistory();

  const debouncedEventQueue: EventQueue = useDebounce(eventQueue, 500);

  const inputOnTimeoutCancel = useCallback(() => {
    setInputOnTimeout((prev) => {
      clearTimeout(prev);
      return undefined;
    });
  }, [setInputOnTimeout]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    switch (history.location.pathname) {
      case '/home':
        window.onpopstate = () => {
          click.play();
          for (let index = 0; index < history.length; index += 1) {
            history.goBack();
          }
        };
        break;

      case '/stars':
      case '/stars_end':
      case '/video':
        window.onpopstate = () => {
          setBackButtonTrigger(true);
          goHomeReady.current = true;
        };
        break;

      case '/house':
        window.onpopstate = () => {
          setBackButtonTrigger(true);
        };
        break;

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  useEffect(() => {
    if (backButtonTrigger) {
      setBackButtonTrigger(false);
      click.play();

      debouncedEventQueue.audioQueue.flat().forEach((audio) => audio.pause());
      lastEvent.current = 0;
      queueLength.current = 0;
      setEventQueue({
        popUpQueue: [],
        backgroundQueue: [],
        audioQueue: [],
      });
      if (goHomeReady.current) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        assistant?.sendData({
          action: {
            action_id: 'inputOn',
            parameters: {
              auto_listening: false,
            },
          },
          name: 'SERVER_ACTION',
        });
        inputOnTimeoutStart(assistant);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        assistant?.sendData({
          action: { action_id: '/start' },
          name: 'SERVER_ACTION',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backButtonTrigger]);

  const getPopUpSettings = (payload: any): PopUpProps => {
    const defaultProps = { type: payload?.type };
    switch (payload.type) {
      case 'BUBBLE':
        return {
          ...defaultProps,
          imageId: payload.image,
          text: payload.image === 'payment' || payload.image === 'textBubble' ? payload.text?.split('\n') : undefined,
        };

      case 'CONFIRM_BUBBLE':
        return {
          ...defaultProps,
          yesEvent: payload?.keyboard ? payload?.keyboard[0].event : '',
          noEvent: payload?.keyboard ? payload?.keyboard[1].event : '',
          imageId: payload.image,
          text: payload.image === 'payment' || payload.image === 'textBubble' ? payload.text : undefined,
          keyboard: payload?.keyboard,
        };

      case 'QUESTION':
        return {
          ...defaultProps,
          questionOptions: payload?.keyboard.map((option: any) => ({
            cover: option.content_url,
            title: option.title,
            payload: option.payload,
            isCorrect: !!option.payload.right,
          })),
        };

      case 'QUESTION_CORRECT_ENDS':
      case 'QUESTION_WRONG_ENDS':
        return { type: 'QUESTION_ENDS' };

      default:
        return { type: '' };
    }
  };

  useEffect(() => {
    if (assistant) {
      assistant.on('data', (data: any) => {
        setTimeoutAction((prev) => {
          clearTimeout(prev.timeout);
          return { timeout: undefined, time: null };
        });
        if (data.type === 'smart_app_error') {
          assistant.sendData({
            action: {
              action_id: 'inputOn',
              parameters: {
                auto_listening: false,
              },
            },
            name: 'SERVER_ACTION',
          });
          inputOnTimeoutStart(assistant);
        }
        if ('actions' in data) {
          data.actions.forEach((action: any) => {
            if (action?.type === 'SHOW_MESSAGE') {
              if (action?.payload?.tts && Array.isArray(action?.payload?.tts)) {
                setEventQueue((prev) => ({
                  ...prev,
                  audioQueue: [...prev.audioQueue, action?.payload?.tts.map((src: string) => new Audio(src))],
                }));
              }
              if (
                action?.payload?.type === 'BUBBLE' ||
                action?.payload?.type === 'CONFIRM_BUBBLE' ||
                action?.payload?.type === 'QUESTION' ||
                action?.payload?.type === 'QUESTION_WRONG' ||
                action?.payload?.type === 'QUESTION_CORRECT' ||
                action?.payload?.type === 'QUESTION_CORRECT_ENDS' ||
                action?.payload?.type === 'QUESTION_WRONG_ENDS' ||
                (action?.payload?.type === 'AUDIO_ONLY' && Array.isArray(action?.payload?.tts))
              ) {
                setEventQueue((prev) => ({
                  ...prev,
                  popUpQueue: [...prev.popUpQueue, getPopUpSettings(action?.payload)],
                }));
              }
              if (action?.payload.background) {
                setEventQueue((prev) => ({
                  ...prev,
                  backgroundQueue: [
                    ...prev.backgroundQueue,
                    action?.payload.background === 'no_id' ? 'video' : action?.payload.background,
                  ],
                }));
                if (
                  action?.payload.background === 'home' &&
                  action?.payload?.type === 'BUBBLE' &&
                  Array.isArray(action?.payload?.keyboard)
                ) {
                  setCardsList([
                    ...action?.payload?.keyboard.map((card: any) => ({
                      title: card.title,
                      imgSrc: card.content_url,
                      event: card.event,
                      videoId: card.payload.video_id,
                      duration: card.payload.duration,
                      questions: card.payload.questions,
                      videoUrl: card.payload?.source_url,
                      locked: card.payload?.blocked,
                    })),
                  ]);
                }
              }
            }
            if (action?.type === 'INPUT_ON') {
              log(`inputOn RECEIVED`);
              inputOnTimeoutCancel();
              setFreezedControls(false);
            }

            if (action?.type === 'INPUT_ON' && goHomeReady.current) {
              goHomeReady.current = false;
              assistant.sendData({
                action: { action_id: 'homeButton' },
                name: 'SERVER_ACTION',
              });
              handleGoHome();
            }
            if ('timeout' in action?.payload) {
              setTimeoutAction({
                timeout: setTimeout(() => {
                  assistant.sendData({
                    action: { action_id: 'timeout' },
                    name: 'SERVER_ACTION',
                  });
                }, action.payload.timeout * 1000),
                time: action.payload.timeout * 1000,
              });
            }
            if ('help' in action?.payload) {
              setHelpVisible(action?.payload?.help);
            }
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assistant, setCardsList, setFreezedControls]);

  useEffect(() => {
    const playEventQueue = () => {
      if (debouncedEventQueue.audioQueue.length < 1) return;

      let eventIndex = lastEvent.current;
      let audioIndex = 0;
      const replay = () => {
        queueLength.current = debouncedEventQueue.audioQueue.length;
        const currentAudio = debouncedEventQueue.audioQueue?.[eventIndex]?.[audioIndex];
        if (!currentAudio) return;
        console.log('popUpQueue', debouncedEventQueue.popUpQueue);
        console.log('currentPopUp', debouncedEventQueue.popUpQueue[eventIndex]);

        if (!window.location.pathname.includes(debouncedEventQueue.backgroundQueue[eventIndex])) {
          history.push(`/${debouncedEventQueue.backgroundQueue[eventIndex]}`);
        }

        setCurrentScreen((prev) =>
          prev === debouncedEventQueue.backgroundQueue[eventIndex]
            ? prev
            : debouncedEventQueue.backgroundQueue[eventIndex],
        );

        if (audioIndex === 0 && eventIndex !== debouncedEventQueue.audioQueue.length) {
          if (debouncedEventQueue.popUpQueue[eventIndex]?.type !== '') {
            if (debouncedEventQueue.popUpQueue[eventIndex]?.type !== 'QUESTION_ENDS') {
              if (debouncedEventQueue.popUpQueue[eventIndex]?.type === 'QUESTION') {
                setFadeAnimation({
                  in: true,
                  start:
                    debouncedEventQueue.popUpQueue[eventIndex - 1]?.type === 'QUESTION_ENDS' &&
                    debouncedEventQueue.popUpQueue[eventIndex]?.type === 'QUESTION',
                });
              }
              if (
                (debouncedEventQueue.popUpQueue[eventIndex]?.type === 'BUBBLE' && eventIndex === 0) ||
                debouncedEventQueue.popUpQueue[eventIndex]?.type === 'BUBBLE' ||
                debouncedEventQueue.popUpQueue[eventIndex]?.type === 'CONFIRM_BUBBLE'
              ) {
                setPopUpFadeAnimation({
                  in: true,
                  start:
                    (debouncedEventQueue.popUpQueue[eventIndex]?.type === 'BUBBLE' && eventIndex === 0) ||
                    (debouncedEventQueue.popUpQueue[eventIndex - 1]?.type === 'QUESTION_END' &&
                      (debouncedEventQueue.popUpQueue[eventIndex]?.type === 'CONFIRM_BUBBLE' ||
                        debouncedEventQueue.popUpQueue[eventIndex]?.type === 'BUBBLE')),
                });
              }
              openPopUp(debouncedEventQueue.popUpQueue[eventIndex]);
            }
          }
        }
        setFreezedControls(true);
        if (
          ((debouncedEventQueue.popUpQueue[eventIndex]?.type === '' ||
            debouncedEventQueue.popUpQueue[eventIndex]?.type === 'QUESTION_ENDS') &&
            debouncedEventQueue.audioQueue[eventIndex][audioIndex]?.src.includes('answers_sounds')) ||
          (debouncedEventQueue.audioQueue[eventIndex][audioIndex]?.src.includes('question_block') &&
            debouncedEventQueue.audioQueue[eventIndex]?.length === 2)
        ) {
          setTimeout(() => {
            // Delay to match question animation
            currentAudio.play();
          }, 1500);
        } else if (
          (debouncedEventQueue.popUpQueue[eventIndex - 1]?.type === 'BUBBLE' ||
            debouncedEventQueue.popUpQueue[eventIndex - 1]?.type === 'QUESTION_ENDS') &&
          debouncedEventQueue.popUpQueue[eventIndex]?.type === 'QUESTION' &&
          audioIndex === 0
        ) {
          setTimeout(() => {
            // Delay to match question animation
            currentAudio.play();
          }, 800);
        } else {
          currentAudio.play();
        }
        currentAudio.addEventListener('ended', () => {
          if (
            currentAudio ===
              debouncedEventQueue.audioQueue?.[queueLength.current - 1]?.[
                debouncedEventQueue.audioQueue?.[queueLength.current - 1]?.length - 1
              ] &&
            debouncedEventQueue?.popUpQueue[eventIndex]?.type === 'QUESTION_ENDS'
          ) {
            closePopUp('QUESTION', 500);
            setFadeAnimation({ in: false, start: false });
          }
          if (
            currentAudio ===
            debouncedEventQueue.audioQueue?.[queueLength.current - 1]?.[
              debouncedEventQueue.audioQueue?.[queueLength.current - 1]?.length - 1
            ]
          )
            if (debouncedEventQueue.popUpQueue[eventIndex]?.type !== '')
              if (debouncedEventQueue.popUpQueue[eventIndex]?.type !== 'QUESTION_ENDS' && assistant) {
                log(`inputOn SENT`);
                assistant.sendData({
                  action: {
                    action_id: 'inputOn',
                    parameters: {
                      keyboard: debouncedEventQueue.popUpQueue[eventIndex]?.keyboard,
                      auto_listening: true,
                    },
                  },
                  name: 'SERVER_ACTION',
                });
                inputOnTimeoutStart(assistant);
              }
          if (audioIndex + 1 === debouncedEventQueue.audioQueue?.[eventIndex]?.length) {
            eventIndex += 1;
            lastEvent.current = eventIndex;
            audioIndex = 0;
            if (
              eventIndex === debouncedEventQueue?.audioQueue?.length &&
              debouncedEventQueue.backgroundQueue?.[eventIndex - 1] === 'home' &&
              debouncedEventQueue.popUpQueue[eventIndex - 1]?.imageId !== 'payment'
            ) {
              setPopUpFadeAnimation({ in: false, start: false });
              closePopUp(debouncedEventQueue.popUpQueue?.[eventIndex - 1]?.type);
            }
            if (eventIndex < debouncedEventQueue.audioQueue?.length) {
              replay();
            }
          } else {
            audioIndex += 1;
            replay();
          }
        });
      };
      replay();
    };

    playEventQueue();
  }, [
    assistant,
    closePopUp,
    debouncedEventQueue,
    history,
    inputOnTimeoutStart,
    log,
    openPopUp,
    setCurrentScreen,
    setFadeAnimation,
    setFreezedControls,
    setPopUpFadeAnimation,
  ]);

  return null;
  // return <Logs>{logs}</Logs>;
};
export default EventController;

/* eslint-disable no-param-reassign */
import React, { useState, useEffect, ReactNode } from 'react';
import styled from 'styled-components';
import { createAssistant, createSmartappDebugger } from '@sberdevices/assistant-client';

import { useLogs } from 'helpers/hooks';
import AssistantContext, { AssistantType } from 'helpers/contexts/assistant';

const Logs = styled.div`
  position: fixed;
  top: 4vh;
  left: 4vh;
  right: 4vh;
  z-index: 9999;
  padding: 2vh;
  background: white;
  word-break: break-word;
`;

type Props = {
  children: ReactNode;
};

const AssistantProvider = ({ children }: Props) => {
  const [logs, log] = useLogs();
  const [assistant, setAssistant] = useState<AssistantType | undefined>();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const initialize = (getState: any) => {
      if (process.env.NODE_ENV === 'development') {
        return createSmartappDebugger({
          token:
            'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MTEyMTg3OTQsImV4cCI6MTYxMTMwNTE5NCwidHlwZSI6IkJlYXJlciIsImp0aSI6IjFhMzQwYzliLTgxZDEtNDg5Yi1hYzAwLTg4OWQzZGYyMzZmZSIsInN1YiI6ImZkNTA2ZTkyMzRhYjM2MDk5YmI5ODMwNTI4MjY1NzFkODY2Njg5ZWQ2MGVlMDI0ZGE4ZTM5YmI2YzM5ZWE1MDg3MmZkYjljN2ZmZTVhYjZjIiwiYXVkIjoiVlBTIn0.Tj9VcZOy_SZhoIdw7XB2hjs9muxD_dytqcVjAliYX3dHUEopgNUexdab5uMcDKSwx_nDn6_4_ZkxK1lGYucSTuNAacf7IiNjz09b5kL5u8Y3X5QWreh-NdYqa76XMnO0_cmQjV80LPq7GN2H7biOW3Hzi2v3nBarzW-GmpiM4VK1rXD6zLBcvop1oD9z8Mr-0ounD4Pif3YabKZD2g4QuxAnRYaE4EhrSmym_OxomPla8JyqMkDIYLWqt6Esnl1pR3BmKe9hX0Hlp69wMpyiCpLMPH9usrJ8Cj552yOOmOj61cXccFeeDL8wEYyHHpmb_eEUAn6CYIs16ymCuSAUlM1nKdsVwWFRMHM6T_sH0TLPH5BWDOFEYze3H-8OF75dIWSEqHNsE4XEUJn3p19HjbmeM3ZXWYU0eZ3QSlHZfnrSmxtefit2YZlpB3XQYAyftCTPZUIeDYXleZ0sxUl9TKQhIxqBSdzPG6k6MuwBHuKP88zOZjLCu2fzC3CNKMGQc8CjKTr8bfPJUKQLKx1gbDoatdO_XWlbDsHwmZHf_iq6RaUP8zYZuVYydyxFaK0nTruoZvjvaS02i9clet7xQmUDkBs3aC6e_tcT_TpSYluOpzrfy2RLTv9_467XUjR0h2luc-fAHCSroc8brleyVu9Yd7vI11V_lnIJRQGeKug',
          initPhrase: 'Запусти озорные щеглы',
          // initPhrase: 'Запусти Коля Коля Николай',
          getState,
        });
      }

      return createAssistant({ getState });
    };

    const ass = initialize(() => ({}));

    ass.on('start', () => {
      log('ON_START-LOADED');
      setLoaded(true);
    });
    ass.on('data', (data: any) => {
      log(`DATA_RECEIVED:${JSON.stringify(data, null, 2)}`);
    });

    setAssistant(ass);
    log('INITIALIZE');
  }, [log]);

  useEffect(() => {
    if (assistant && loaded) {
      log('START');
      // assistant.sendData({
      //   action: { action_id: '/start' },
      //   name: 'SERVER_ACTION',
      // });
      // assistant.sendData({
      //   action: { action_id: '/resetUser' },
      //   name: 'SERVER_ACTION',
      // });
    }
  }, [assistant, loaded, log]);

  return (
    <AssistantContext.Provider value={assistant}>
      {/* <Logs>{logs}</Logs> */}
      {children}
    </AssistantContext.Provider>
  );
};

export default AssistantProvider;

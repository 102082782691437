import React, { useState, useEffect, useMemo, useCallback } from 'react';
import useSound, { HookOptionsExt } from 'use-sound';
import { BrowserRouter as Router } from 'react-router-dom';

import mainTheme from 'assets/sounds/main-theme.mp3';
import endTheme from 'assets/sounds/end-theme.mp3';
import welcomeBg from 'assets/img/backgrounds/welcome-bg.webp';

import { AssistantProvider, DeviceProvider } from 'components/providers';
import { usePopUp } from 'helpers/hooks';
import { Gradient, HelpButton, ScreenWrapper, Stars, Sticker } from 'components/ui';
import { getStickerById, Stickers } from 'helpers/stickers';
import EventController from 'components/EventController';
import { ConfirmPopUpProps, QuestionPopUpProps, StickerPopUpProps } from 'helpers/hooks/usePopUp';
import { AssistantType } from 'helpers/contexts/assistant';

import Welcome from './Welcome';
import Confirm from './Confirm';
import Home, { CardItem } from './Home';
import Video from './video';
import Question from './question';

type Screen = {
  [key: string]: JSX.Element;
};

const App = () => {
  const [videoPaused, setVideoPaused] = useState<boolean>(false);
  const [currentScreen, setCurrentScreen] = useState<string>('welcome');
  const [currentPopUp, popUpAnimated, openPopUp, closePopUp] = usePopUp(setVideoPaused);
  const [cardsList, setCardsList] = useState<CardItem[]>([]);
  const [currentVideoInfo, setCurrentVideoInfo] = useState<{
    src: string;
    videoId: number | undefined;
  }>({
    src: '',
    videoId: undefined,
  });
  const [freezedControls, setFreezedControls] = useState(true);
  const [fadeAnimation, setFadeAnimation] = useState({ in: false, start: false });
  const [popUpFadeAnimation, setPopUpFadeAnimation] = useState({ in: false, start: false });
  const [timeoutAction, setTimeoutAction] = useState<{ timeout: number | undefined; time: number | null }>({
    timeout: undefined,
    time: null,
  });
  const [helpVisible, setHelpVisible] = useState<boolean>(false);
  const [inputOnTimeout, setInputOnTimeout] = useState<number | undefined>(undefined);

  const getSticker = useCallback(getStickerById, []);

  const inputOnTimeoutStart = useCallback((assistant: AssistantType) => {
    setInputOnTimeout(
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        assistant?.sendData({
          action: {
            action_id: 'inputOn',
            parameters: {
              auto_listening: false,
            },
          },
          name: 'SERVER_ACTION',
        });
      }, 3000),
    );
  }, []);

  console.log('currentPopUp', currentPopUp);
  const [playMainTheme, { pause: pauseMainTheme }] = useSound(mainTheme, {
    loop: true,
    interrupt: false,
    autoplay: true,
  } as HookOptionsExt);

  const [playQuestionsTheme, { stop: pauseQuestionsTheme }] = useSound(mainTheme, {
    loop: true,
    interrupt: true,
    volume: 0.6,
  } as HookOptionsExt);

  const [playEndTheme, { stop: pauseEndTheme }] = useSound(endTheme, {
    loop: true,
    interrupt: true,
    volume: 0.25,
  } as HookOptionsExt);

  const handleGoHome = useCallback(() => {
    setTimeout(() => {
      pauseEndTheme();
      playMainTheme();
      setCurrentScreen('home');
    }, 500);
  }, [pauseEndTheme, playMainTheme]);

  const handleVideoStarted = useCallback(
    (info: { src: string; videoId: number | undefined }) => {
      pauseMainTheme();
      setCurrentVideoInfo(info);
      setCurrentScreen('video');
    },
    [pauseMainTheme],
  );

  const handleVideoEnded = useCallback(() => {
    openPopUp({ type: 'BLACKOUT' });
  }, [openPopUp]);

  useEffect(() => {
    if (currentScreen === 'home' || currentScreen === 'house') {
      pauseQuestionsTheme();
      pauseMainTheme();
      pauseEndTheme();
      playMainTheme();
    }
  }, [currentScreen, pauseEndTheme, pauseMainTheme, pauseQuestionsTheme, playMainTheme]);

  useEffect(() => {
    if (currentScreen === 'stars') {
      pauseQuestionsTheme();
      pauseMainTheme();
      pauseEndTheme();
      pauseEndTheme();
      playQuestionsTheme();
    }
  }, [currentScreen, pauseEndTheme, pauseMainTheme, pauseQuestionsTheme, playQuestionsTheme]);

  useEffect(() => {
    if (currentScreen === 'stars_end') {
      pauseMainTheme();
      pauseEndTheme();
      pauseQuestionsTheme();
      playEndTheme();
    }
  }, [currentScreen, pauseEndTheme, playEndTheme, pauseQuestionsTheme, pauseMainTheme]);

  const screen = useMemo<Screen>(
    () => ({
      welcome: <Welcome />,
      home: (
        <Home
          onCardClick={handleVideoStarted}
          cardsList={cardsList}
          freezedControls={freezedControls || currentPopUp.type !== 'null'}
          inputOnTimeoutStart={inputOnTimeoutStart}
        />
      ),
      video: (
        <Video
          paused={videoPaused}
          onGoHome={handleGoHome}
          onGoPrev={pauseEndTheme}
          onGoNext={pauseEndTheme}
          onVideoEnded={handleVideoEnded}
          currentVideoInfo={currentVideoInfo}
          setCurrentVideoInfo={setCurrentVideoInfo}
          cardsList={cardsList}
          inputOnTimeoutStart={inputOnTimeoutStart}
        />
      ),
      house: <ScreenWrapper bg={`url(${welcomeBg}) #704be9`} />,
      stars: <Stars startFromFade />,
      stars_end: <Stars />,
    }),
    [
      cardsList,
      currentPopUp.type,
      currentVideoInfo,
      freezedControls,
      handleGoHome,
      handleVideoEnded,
      handleVideoStarted,
      inputOnTimeoutStart,
      pauseEndTheme,
      videoPaused,
    ],
  );

  const popUp = useCallback(() => {
    switch (currentPopUp?.type) {
      case 'BUBBLE':
        return (
          <Sticker
            imgSrc={getSticker((currentPopUp as StickerPopUpProps).imageId as keyof Stickers)}
            animActive={popUpAnimated}
            popUpFadeAnimation={popUpFadeAnimation}
            text={(currentPopUp as StickerPopUpProps).text}
          />
        );
      case 'CONFIRM_BUBBLE':
        return (
          <Confirm
            stickerSrc={getSticker((currentPopUp as ConfirmPopUpProps).imageId as keyof Stickers)}
            animActive={popUpAnimated}
            popUpProps={currentPopUp as ConfirmPopUpProps}
            freezedControls={freezedControls}
            popUpFadeAnimation={popUpFadeAnimation}
          />
        );
      case 'QUESTION':
        return (
          <Question
            animActive={popUpAnimated}
            popUpProps={currentPopUp as QuestionPopUpProps}
            freezedControls={freezedControls}
            fadeAnimation={fadeAnimation}
          />
        );
      case 'GRADIENT':
        return (
          <Gradient
            animActive={popUpAnimated}
            color="linear-gradient(180deg,rgba(0, 38, 235, 0) -19.23%,rgba(77, 63, 231, 0.61) 35.72%,rgba(4, 3, 79, 0.9) 77.58%)"
          />
        );
      case 'BLACKOUT':
        return <Gradient animActive={popUpAnimated} color="#000" />;

      default:
        return null;
    }
  }, [currentPopUp, fadeAnimation, freezedControls, getSticker, popUpAnimated, popUpFadeAnimation]);

  return (
    <AssistantProvider>
      <DeviceProvider>
        <Router>
          {currentScreen !== 'video' && currentScreen !== 'welcome' && helpVisible && (
            <HelpButton
              freezedControls={freezedControls}
              setFreezedControls={setFreezedControls}
              currentScreen={currentScreen}
              setTimeoutAction={setTimeoutAction}
            />
          )}
          <EventController
            openPopUp={openPopUp}
            closePopUp={closePopUp}
            setCurrentScreen={setCurrentScreen}
            setCardsList={setCardsList}
            setFreezedControls={setFreezedControls}
            setFadeAnimation={setFadeAnimation}
            setPopUpFadeAnimation={setPopUpFadeAnimation}
            handleGoHome={handleGoHome}
            setTimeoutAction={setTimeoutAction}
            setHelpVisible={setHelpVisible}
            inputOnTimeoutStart={inputOnTimeoutStart}
            setInputOnTimeout={setInputOnTimeout}
          />
          {popUp()}
          {screen[currentScreen]}
        </Router>
      </DeviceProvider>
    </AssistantProvider>
  );
};

export default App;

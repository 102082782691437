/* eslint-disable import/prefer-default-export */

const padLeft = (num: number, digits: number) => num.toString().padStart(digits, '0');

export const getTime = (_secs: number | undefined) => {
  if (_secs === undefined || _secs < 0) return '';

  const hours = Math.floor(_secs / 3600);
  const mins = Math.floor(_secs / 60) - hours * 60;
  const secs = Math.round(_secs - hours * 3600 - mins * 60);

  if (hours > 0) return `${hours}:${padLeft(mins, 2)}:${padLeft(secs, 2)}`;
  return `${mins}:${padLeft(secs, 2)}`;
};

export const onImagesLoad = (urls: string[], callback?: () => void) =>
  Promise.all(
    urls.map(
      (src) =>
        new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = (event) => resolve(event.target);
          img.onerror = (error) => reject(error);
        }),
    ),
  ).then(callback);

export const onSoundsLoad = (urls: string[], callback?: any) =>
  Promise.all(
    urls.map(
      (src) =>
        new Promise((resolve, reject) => {
          const snd = new Audio(src);
          snd.load();
          snd.onload = (event) => resolve(event.target);
          snd.onerror = (error) => reject(error);
        }),
    ),
  ).then(callback);

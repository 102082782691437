import React from 'react';
import styled from 'styled-components';
import lockIcon from 'assets/img/icons/lock-icon.webp';

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(72, 182, 63, 0.66), rgba(72, 182, 63, 0.66));

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 2;
`;

const LockIcon = styled.img`
  width: 22.2222vh;
  height: 20.009;
  filter: drop-shadow(0 0.74vh 7.407vh ${({ theme }) => theme.colors.sberGold});

  animation: ${({ theme }) => theme.animations.keyframes.dropShadowRippleGold} 4s infinite;
`;

const Lock = () => (
  <Wrapper>
    <LockIcon src={lockIcon} alt="Нарисованный замок" />
  </Wrapper>
);

export default React.memo(Lock);

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import ProgressiveImage from 'react-progressive-image';
import { useHistory } from 'react-router-dom';

import homeBgPrev from 'assets/img/backgrounds/home-bg-preview.webp';
import homeBg from 'assets/img/backgrounds/home-bg.webp';
import clickSound from 'assets/sounds/btn-click.wav';

import { ScreenWrapper, Card } from 'components/ui';
import { useAssistant, useCardsControls, useDevice } from 'helpers/hooks';

const CardsWrapper = styled.main`
  position: absolute;
  top: 18vh;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 3.7625vh;
  line-height: 4.44vh;
  letter-spacing: -0.025em;

  margin-left: 6.77vw;
  margin-bottom: 3.174vh;
`;

const CardsListWrapper = styled.section<{ freezedControls: boolean }>`
  width: 100vw;
  overflow-x: ${({ freezedControls }) => (freezedControls ? 'hidden' : 'scroll')};
  padding-bottom: 10vh;

  display: flex;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const CardsList = styled.div<{ scrollPos: number }>`
  display: inline-flex;
  flex-direction: row;
  padding: 0 6.77vw;
  transition: margin-left 0.25s ease-in-out;

  margin-left: ${({ scrollPos }) => scrollPos * 40.741}vh;

  > *:not(:last-child) {
    margin-right: 2.963vh;
  }
`;

export type CardItem = {
  title: string;
  imgSrc: string;
  duration: number;
  videoUrl?: string;
  videoId: number;
  event: string;
  questions: number[];
  locked: boolean;
};

export type HandleCardClick = (id: number) => void;

type Props = {
  onCardClick: ({ src, videoId }: { src: string; videoId: number | undefined }) => void;
  cardsList: CardItem[];
  freezedControls: boolean;
  inputOnTimeoutStart: Function;
};

const Home = ({ onCardClick, cardsList, freezedControls, inputOnTimeoutStart }: Props) => {
  const [id, setId] = useState<number>(0);
  const isSberPortal = useDevice();
  const click = useMemo(() => new Audio(clickSound), []);
  const history = useHistory();
  const assistant = useAssistant();

  const handleCardClick: HandleCardClick = useCallback(
    (idx: number) => {
      if (freezedControls) return;
      click.play();
      if (assistant) {
        assistant.sendData({
          action: {
            action_id: cardsList[isSberPortal ? idx : id].event,
            parameters: { video_id: cardsList[isSberPortal ? idx : id].videoId },
          },
          name: 'SERVER_ACTION',
        });
      }
    },
    [assistant, cardsList, click, freezedControls, id, isSberPortal],
  );
  const [cardFocusedIdx, prevCardIdx] = useCardsControls(cardsList.length, handleCardClick, !freezedControls);

  useEffect(() => {
    setId(cardFocusedIdx);
  }, [cardFocusedIdx]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    assistant?.on('data', (data: any) => {
      if ('actions' in data) {
        data.actions.forEach((action: any, index: number) => {
          if (action?.type === 'PLAY_VIDEO') {
            setTimeout(
              () => {
                onCardClick({
                  src: isSberPortal ? action?.payload.url_hd : action?.payload.url,
                  videoId: action?.payload.info.video_id,
                });
                history.push(`/video`);
              },
              index === 0 ? 500 : 5000,
            );
          }
        });
      }
    });
  }, [assistant, history, isSberPortal, onCardClick]);

  useEffect(() => {
    if (assistant) {
      setTimeout(() => {
        assistant.sendData({
          action: {
            action_id: 'inputOn',
            parameters: {
              auto_listening: false,
            },
          },
          name: 'SERVER_ACTION',
        });
        inputOnTimeoutStart(assistant);
      }, 500);
    }
  }, [assistant, inputOnTimeoutStart]);

  const scrollPos = useMemo((): number => {
    if (cardFocusedIdx > 1) {
      return -(cardFocusedIdx - 1);
    }
    if (cardFocusedIdx === null) {
      if (prevCardIdx > 1) {
        return -(prevCardIdx - 1);
      }
    }
    return 0;
  }, [cardFocusedIdx, prevCardIdx]);

  return (
    <ProgressiveImage placeholder={homeBgPrev} src={homeBg}>
      {(src: string) => (
        <ScreenWrapper bg={`url(${src}) #1e278d`}>
          <CardsWrapper>
            <Title>Все серии</Title>

            <CardsListWrapper freezedControls={freezedControls}>
              <CardsList scrollPos={scrollPos}>
                {cardsList.map((cardItem, idx) => (
                  <Card
                    key={cardItem.videoId}
                    title={cardItem.title}
                    imgSrc={cardItem.imgSrc}
                    count={idx + 1}
                    focused={!isSberPortal && cardFocusedIdx === idx}
                    duration={cardItem.duration}
                    locked={cardItem.locked}
                    onClick={() => (isSberPortal ? handleCardClick(idx % cardsList.length) : undefined)}
                  />
                ))}
              </CardsList>
            </CardsListWrapper>
          </CardsWrapper>
        </ScreenWrapper>
      )}
    </ProgressiveImage>
  );
};

export default React.memo(Home);
